import React from 'react'; 
import Slider from './Slider';
import Trabajo from './Trabajo';
import Politicas from './Politicas';
import Video from './Video';
import Proceso from './Proceso';
import Cotizador from '../cotizador/Index';

 
 
class Home extends React.Component {
 
	render() {
 
		return(
			<>
							<Cotizador/>
				<Slider/>
				<Trabajo/>
				<Politicas/>
				<Video/>
				<Proceso/>

			</>
 
		)
 
	}
 
}
 
export default Home;