import React from 'react';

class Slider extends React.Component {
 
    render() {
   
        return (
          <div
          class="main-image-section"
          style={{backgroundImage: `url(img/magma-cumplimiento.jpg)`}}
        >
          <div class="main-image-content">
            <div class="container">
              <div class="row">
                <div class="col-md-8 col-sm-12 col-12 offset-md-2">
                  <div class="center-holder">
                    <h2>Seguridad, transparencia y mejora continua.</h2>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-12 offset-md-3">
                  <div class="center-holder">
                    <p>Dando estricto cumplimiento a la normatividad vigente.</p>
                    <div class="mt-30">
                      <a href="#contact" class="primary-button button-md"
                        >SABER MÁS</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
   
        )
      
    }
   
  }
   
  export default Slider;