import React from 'react';

class Slider extends React.Component {
 
    render() {
   
        return (
            <section className="example">
            <article className="content">
              <div
                id="rev_slider_24_1_wrapper"
                className="rev_slider_wrapper fullscreen-container"
                data-alias="website-intro"
                data-source="gallery"
              >
                <div
                  id="rev_slider_24_1"
                  className="rev_slider fullscreenbanner tiny_bullet_slider"
                  style={{display: 'none'}}
                  data-version="5.4.1"
                >
                  <ul>
                    <li
                      data-index="rs-67"
                      data-transition="fade"
                      data-slotamount="default"
                      data-hideafterloop="0"
                      data-hideslideonmobile="off"
                      data-easein="default"
                      data-easeout="default"
                      data-masterspeed="600"
                      data-thumb="img/magma-slide1.jpg"
                      data-rotate="0"
                      data-saveperformance="off"
                      data-title="Slide"
                      data-param1=""
                      data-param2=""
                      data-param3=""
                      data-param4=""
                      data-param5=""
                      data-param6=""
                      data-param7=""
                      data-param8=""
                      data-param9=""
                      data-param10=""
                      data-description=""
                      data-slicey_shadow="0px 0px 0px 0px transparent"
                    >
                      <img
                        src="img/magma-slide1.jpg"
                        alt=""
                        data-bgposition="center center"
                        data-kenburns="on"
                        data-duration="5000"
                        data-ease="Power2.easeInOut"
                        data-scalestart="100"
                        data-scaleend="150"
                        data-rotatestart="0"
                        data-rotateend="0"
                        data-blurstart="20"
                        data-blurend="0"
                        data-offsetstart="0 0"
                        data-offsetend="0 0"
                        className="rev-slidebg"
                        data-no-retina
                      />
                      <div
                        className="
                          tp-caption tp-shape tp-shapewrapper tp-slicey tp-resizeme
                        "
                        id="slide-67-layer-9"
                        data-x="['center','center','center','center']"
                        data-hoffset="['-112','-43','-81','44']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['-219','-184','-185','182']"
                        data-width="['250','250','150','150']"
                        data-height="['150','150','100','100']"
                        data-whitespace="nowrap"
                        data-type="shape"
                        data-slicey_offset="250"
                        data-slicey_blurstart="0"
                        data-slicey_blurend="20"
                        data-responsive_offset="on"
                        data-frames='[{"delay":300,"speed":1000,"frame":"0","from":"rX:0deg;rY:0deg;rZ:0deg;sX:1;sY:1;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"+3700","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{zIndex: '5', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
                      ></div>
                      <div
                        className="
                          tp-caption tp-shape tp-shapewrapper tp-slicey tp-resizeme
                        "
                        id="slide-67-layer-10"
                        data-x="['center','center','center','center']"
                        data-hoffset="['151','228','224','117']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['-212','-159','71','-222']"
                        data-width="['150','150','100','100']"
                        data-height="['200','150','150','150']"
                        data-whitespace="nowrap"
                        data-type="shape"
                        data-slicey_offset="250"
                        data-slicey_blurstart="0"
                        data-slicey_blurend="20"
                        data-responsive_offset="on"
                        data-frames='[{"delay":350,"speed":1000,"frame":"0","from":"sX:1;sY:1;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"+3650","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{zIndex: '6', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
                      ></div>
                      <div
                        className="
                          tp-caption tp-shape tp-shapewrapper tp-slicey tp-resizeme
                        "
                        id="slide-67-layer-29"
                        data-x="['center','center','center','center']"
                        data-hoffset="['339','-442','104','-159']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['2','165','-172','219']"
                        data-width="['250','250','150','150']"
                        data-height="['150','150','100','100']"
                        data-whitespace="nowrap"
                        data-type="shape"
                        data-slicey_offset="250"
                        data-slicey_blurstart="0"
                        data-slicey_blurend="20"
                        data-responsive_offset="on"
                        data-frames='[{"delay":400,"speed":1000,"frame":"0","from":"sX:1;sY:1;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"+3600","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{zIndex: '7', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
                      ></div>
                      <div
                        className="
                          tp-caption tp-shape tp-shapewrapper tp-slicey tp-resizeme
                        "
                        id="slide-67-layer-12"
                        data-x="['center','center','center','center']"
                        data-hoffset="['162','216','-239','193']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['195','245','6','146']"
                        data-width="['250','250','100','100']"
                        data-height="150"
                        data-whitespace="nowrap"
                        data-type="shape"
                        data-slicey_offset="250"
                        data-slicey_blurstart="0"
                        data-slicey_blurend="20"
                        data-responsive_offset="on"
                        data-frames='[{"delay":450,"speed":1000,"frame":"0","from":"opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"+3550","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{zIndex: '8', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
                      ></div>
                      <div
                        className="
                          tp-caption tp-shape tp-shapewrapper tp-slicey tp-resizeme
                        "
                        id="slide-67-layer-34"
                        data-x="['center','center','center','center']"
                        data-hoffset="['-186','-119','273','-223']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['269','217','-121','69']"
                        data-width="['300','300','150','150']"
                        data-height="['200','200','150','150']"
                        data-whitespace="nowrap"
                        data-type="shape"
                        data-slicey_offset="250"
                        data-slicey_blurstart="0"
                        data-slicey_blurend="20"
                        data-responsive_offset="on"
                        data-frames='[{"delay":500,"speed":1000,"frame":"0","from":"sX:1;sY:1;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"+3500","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{zIndex: '9', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
                      ></div>
                      <div
                        className="
                          tp-caption tp-shape tp-shapewrapper tp-slicey tp-resizeme
                        "
                        id="slide-67-layer-11"
                        data-x="['center','center','center','center']"
                        data-hoffset="['-325','292','162','-34']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['3','55','-275','-174']"
                        data-width="150"
                        data-height="['250','150','50','50']"
                        data-whitespace="nowrap"
                        data-type="shape"
                        data-slicey_offset="250"
                        data-slicey_blurstart="0"
                        data-slicey_blurend="20"
                        data-responsive_offset="on"
                        data-frames='[{"delay":550,"speed":1000,"frame":"0","from":"opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"+3450","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{zIndex: '10', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
                      ></div>
                      <div
                        className="
                          tp-caption tp-shape tp-shapewrapper tp-slicey tp-resizeme
                        "
                        id="slide-67-layer-27"
                        data-x="['center','center','center','center']"
                        data-hoffset="['-429','523','-190','-306']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['-327','173','181','480']"
                        data-width="['250','250','150','150']"
                        data-height="['300','300','150','150']"
                        data-whitespace="nowrap"
                        data-type="shape"
                        data-slicey_offset="300"
                        data-slicey_blurstart="0"
                        data-slicey_blurend="20"
                        data-responsive_offset="on"
                        data-frames='[{"delay":320,"speed":1000,"frame":"0","from":"sX:1;sY:1;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"+3680","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{zIndex: '11', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
                      ></div>
                      <div
                        className="
                          tp-caption tp-shape tp-shapewrapper tp-slicey tp-resizeme
                        "
                        id="slide-67-layer-28"
                        data-x="['center','center','center','center']"
                        data-hoffset="['422','-409','208','225']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['-245','-72','294','-14']"
                        data-width="['300','300','150','150']"
                        data-height="['250','250','100','100']"
                        data-whitespace="nowrap"
                        data-type="shape"
                        data-slicey_offset="300"
                        data-slicey_blurstart="0"
                        data-slicey_blurend="20"
                        data-responsive_offset="on"
                        data-frames='[{"delay":360,"speed":1000,"frame":"0","from":"sX:1;sY:1;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"+3640","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{zIndex: '12', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
                      ></div>
                      <div
                        className="
                          tp-caption tp-shape tp-shapewrapper tp-slicey tp-resizeme
                        "
                        id="slide-67-layer-30"
                        data-x="['center','center','center','center']"
                        data-hoffset="['549','-445','28','58']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['236','400','316','287']"
                        data-width="['300','300','150','200']"
                        data-height="['250','250','150','50']"
                        data-whitespace="nowrap"
                        data-type="shape"
                        data-slicey_offset="300"
                        data-slicey_blurstart="0"
                        data-slicey_blurend="20"
                        data-responsive_offset="on"
                        data-frames='[{"delay":400,"speed":1000,"frame":"0","from":"sX:1;sY:1;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"+3600","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{zIndex: '13', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
                      ></div>
                      <div
                        className="
                          tp-caption tp-shape tp-shapewrapper tp-slicey tp-resizeme
                        "
                        id="slide-67-layer-31"
                        data-x="['center','center','center','center']"
                        data-hoffset="['-522','492','-151','262']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['339','-180','330','-141']"
                        data-width="['300','300','150','150']"
                        data-height="['250','250','100','100']"
                        data-whitespace="nowrap"
                        data-type="shape"
                        data-slicey_offset="300"
                        data-slicey_blurstart="0"
                        data-slicey_blurend="20"
                        data-responsive_offset="on"
                        data-frames='[{"delay":440,"speed":1000,"frame":"0","from":"sX:1;sY:1;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"+3560","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{zIndex: '14', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
                      ></div>
                      <div
                        className="
                          tp-caption tp-shape tp-shapewrapper tp-slicey tp-resizeme
                        "
                        id="slide-67-layer-32"
                        data-x="['center','center','center','center']"
                        data-hoffset="['-588','-375','-253','-207']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['72','-328','-172','-111']"
                        data-width="['300','300','150','150']"
                        data-height="['200','200','150','150']"
                        data-whitespace="nowrap"
                        data-type="shape"
                        data-slicey_offset="300"
                        data-slicey_blurstart="0"
                        data-slicey_blurend="20"
                        data-responsive_offset="on"
                        data-frames='[{"delay":480,"speed":1000,"frame":"0","from":"sX:1;sY:1;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"+3520","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{zIndex: '15', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
                      ></div>
                      <div
                        className="
                          tp-caption tp-shape tp-shapewrapper tp-slicey tp-resizeme
                        "
                        id="slide-67-layer-33"
                        data-x="['center','center','center','center']"
                        data-hoffset="['-37','73','-76','-100']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['-401','-340','-293','-246']"
                        data-width="['450','400','250','250']"
                        data-height="['100','100','50','50']"
                        data-whitespace="nowrap"
                        data-type="shape"
                        data-slicey_offset="250"
                        data-slicey_blurstart="0"
                        data-slicey_blurend="20"
                        data-responsive_offset="on"
                        data-frames='[{"delay":310,"speed":1000,"frame":"0","from":"sX:1;sY:1;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"+3690","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{zIndex: '16', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
                      ></div>
                      <div
                        className="
                          tp-caption tp-shape tp-shapewrapper tp-slicey tp-resizeme
                        "
                        id="slide-67-layer-35"
                        data-x="['center','center','center','center']"
                        data-hoffset="['186','38','116','17']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['363','402','190','395']"
                        data-width="['350','400','250','250']"
                        data-height="['100','100','50','50']"
                        data-whitespace="nowrap"
                        data-type="shape"
                        data-slicey_offset="250"
                        data-slicey_blurstart="0"
                        data-slicey_blurend="20"
                        data-responsive_offset="on"
                        data-frames='[{"delay":340,"speed":1000,"frame":"0","from":"sX:1;sY:1;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"+3660","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{zIndex: '17', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
                      ></div>
                      <div
                        className="tp-caption tp-shape tp-shapewrapper"
                        id="slide-67-layer-1"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['0','0','0','0']"
                        data-width="full"
                        data-height="full"
                        data-whitespace="nowrap"
                        data-type="shape"
                        data-basealign="slide"
                        data-responsive_offset="off"
                        data-responsive="off"
                        data-frames='[{"delay":10,"speed":500,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"wait","speed":500,"frame":"999","to":"opacity:0;","ease":"Power4.easeOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{zIndex: '18', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
                      ></div>
                      <div
                        className="tp-caption rev-slider-content tp-resizeme"
                        id="slide-67-layer-2"
                        data-x="['center','center','center','center']"
                        data-hoffset="['1','1','0','0']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['-70','-70','-130','-70']"
                        data-fontsize="['80','80','60','40']"
                        data-lineheight="['100','100','80','50']"
                        data-width="['none','none','481','360']"
                        data-height="none"
                        data-whitespace="['nowrap','nowrap','normal','normal']"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[{"delay":1000,"speed":2000,"frame":"0","from":"sX:0.9;sY:0.9;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":500,"frame":"999","to":"sX:0.9;sY:0.9;opacity:0;fb:20px;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['center','center','center','center']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,70,70]"
                        data-paddingleft="[0,0,0,0]"
                      >
                        Comercializadores y exportadores <br />
                        de metales preciosos.
                      </div>
                      <div
                        className="tp-caption revolution-btn-2 tp-resizeme"
                        id="slide-67-layer-3"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['90','90','60','40']"
                        data-fontsize="['25','25','25','20']"
                        data-lineheight="['35','35','35','30']"
                        data-width="['480','480','480','360']"
                        data-height="none"
                        data-whitespace="normal"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[{"delay":1000,"speed":2000,"frame":"0","from":"sX:0.9;sY:0.9;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":500,"frame":"999","to":"sX:0.9;sY:0.9;opacity:0;fb:20px;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['center','center','center','center']"
                        data-paddingtop="[0,0,80,80]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                      >
                        Compramos, fundimos y exportamos oro de alta calidad para
                        refinerías internacionales.
                      </div>
                      <a
                        className="
                          revolution-btn
                          button-md
                          primary-button
                          tp-caption
                          rev-btn
                          tp-resizeme
                        "
                        href="#"
                        id="slide-67-layer-7"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['200','200','160','160']"
                        data-width="250"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-type="button"
                        data-actions=""
                        data-responsive_offset="on"
                        data-frames='[{"delay":1000,"speed":2000,"frame":"0","from":"sX:0.9;sY:0.9;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":500,"frame":"999","to":"sX:0.9;sY:0.9;opacity:0;fb:20px;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"0","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;fb:0;","style":"c:rgba(255,255,255,1);bs:solid;bw:0 0 0 0;"}]'
                        data-textAlign="['center','center','center','center']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[50,50,50,50]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[50,50,50,50]"
                        >SABER MÁS</a
                      >
                    </li>
                  </ul>
                  <div className="tp-bannertimer tp-bottom"></div>
                </div>
              </div>
            </article>
          </section>
   
        )
      
    }
   
  }
   
  export default Slider;