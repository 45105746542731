import React from 'react';

class Trabajo extends React.Component {
 
    render() {
   
        return (
            <div
            className="section-block-bg"
            style={{backgroundImage: `url(img/magma-trabajo.jpg`}}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-12 col-12">
                  <div className="section-heading">
                    <span>Sostenido, confiable y transparente.</span>
                    <h3>TRABAJO DE MAGMA</h3>
                    <div className="section-heading-line-left"></div>
                  </div>
                  <div className="text-content-big mt-20">
                    <p>
                      El trabajo de Magma a través de su SAGRILAFT ha permitido crear
                      un sistema capaz de monitorear y detectar actividades
                      sospechosas en el sector real, al capacitar a nuestro talento
                      humano en las mejores prácticas internacionales ALA/CFT.
                    </p>
                    <p>
                      Justamente nuestro talento humano, es quién a través de su
                      compromiso, nos identifica como una comercializadora de metales
                      preciosos confiable para las refinadoras internacionales, lo que
                      nos permite un crecimiento sostenido, confiable, rentable y
                      perdurable en el tiempo.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
   
        )
      
    }
   
  }
   
  export default Trabajo;