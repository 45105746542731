import React from 'react';

class Video extends React.Component {
 
    render() {
   
        return (
            <div className="video-section">
            <div className="video-area" id="video-area">
              <div
                className="player"
                id="video-play"
                data-property="{videoURL:'https://www.youtube.com/watch?v=buBvqw6G3WU', containment:'#video-area', showControls:false, autoPlay:true, zoom:0, loop:true, mute:true, startAt:55, opacity:1, quality:'low',}"
              ></div>
              <div className="video-overlay">
                <div className="video-content">
                  <div className="section-heading center-holder white-color">
                    <span>Respeto al medio ambiente</span>
                    <h2><strong>Compra, fundición y exportación</strong></h2>
                    <h4>para el desarrollo sostenible del sector.</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
   
        )
      
    }
   
  }
   
  export default Video;