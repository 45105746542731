import React from 'react'; 
import Slider from './Slider';
import Seccion from './Seccion';
import Compromiso from './Compromiso';
import Declaracion from './Declaracion';
import Aliados from './Aliados';

 
 
class Cumplimiento extends React.Component {
 
	render() {
 
		return(
			<>
				<Slider/>
				<Seccion/>
				<Compromiso/>
				<Declaracion/>
				<Aliados/>
			</>
 
		)
 
	}
 
}

export default Cumplimiento;