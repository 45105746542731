import React from 'react';

class Declaracion extends React.Component {
 
    render() {
   
        return (
            <div class="section-block">
            <div class="container">
              <div class="section-heading center-holder">
                <h3>Aliados</h3>
                <div class="section-heading-line"></div>
              </div>
              <div class="row mt-50">
                <div class="col-md-4 col-sm-4 col-12">
                  <div class="service-simple">
                    <img src="img/Magma_brinks.png" alt="Magma brinks" />
                    <div class="service-simple-inner">
                      <h4>BRINK´S</h4>
                      <p>
                        Como aliado estratégico Brinks nos ofrece un sistema de
                        seguridad integral al optimizar las operaciones, proteger el
                        traslado del material mediante servicios inteligentes de
                        seguridad y transporte internacional de objetos de valor.<br /><br /><br />
                      </p>
                    </div>
                  </div>
                </div>
      
                <div class="col-md-4 col-sm-4 col-12">
                  <div class="service-simple">
                    <img src="img/Magma_Blue_eagle.png" alt="Magma Blue eagle" />
                    <div class="service-simple-inner">
                      <h4>BLUE EAGLE</h4>
                      <p>
                        Como proveedor de servicios jurídicos, contables y de comercio
                        de exterior, la firma BLUE EAGLE nos brinda un portafolio de
                        servicios especializados y externos a fin de acompañar cada
                        uno de nuestros procesos desde la visión de expertos del
                        sector.<br /><br />
                      </p>
                    </div>
                  </div>
                </div>
      
                <div class="col-md-4 col-sm-4 col-12">
                  <div class="service-simple">
                    <img src="img/Magma_Rionegro.png" alt="Magma Rionegro" />
                    <div class="service-simple-inner">
                      <h4>AGENCIA DE ADUANAS</h4>
                      <p>
                        Como aliado estratégico la Agencia de Aduanas, nos acompaña en
                        todo el proceso de importación desde el Territorio Aduanero
                        Nacional -TAN-, así como la exportación desde Zona Franca al
                        resto del mundo, brindando seguridad y transparencia frente a
                        la autoridad aduanera Colombiana.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
   
        )
      
    }
   
  }
   
  export default Declaracion;