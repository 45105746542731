import React from 'react';

class Nosotros extends React.Component {
 
    render() {
   
        return (
            <div class="section-block">
            <div class="container">
              <div class="section-heading center-holder">
                <span
                  >Nuestro equipo se destaca por su sólida trayectoria, el amplio
                  conocimiento como comercializadores y la experiencia como
                  exportadores de metales preciosos de más de 28 años en el mercado,
                  que nos permite junto a nuestros aliados ser una empresa con altos
                  estándares de calidad, fidelidad y apego a la ley mediante un
                  sistema propio y transparente que alienta la participación en el
                  sector minero del país.</span
                >
                <div class="section-heading-line"></div>
              </div>
              <div class="row mt-50">
                <div class="col-md-4 col-sm-4 col-12">
                  <div class="feature-box-long">
                    <i class="icon-target"></i>
                    <h3>Quienes somos</h3>
                    <p>
                      Somos una empresa usuaria aduanera de zona franca que brinda
                      servicios de comercialización, y exportación de metales
                      preciosos, promoviendo el desarrollo sostenible y el respeto al
                      medio ambiente.<br /><br /><br />
                    </p>
                  </div>
                </div>
      
                <div class="col-md-4 col-sm-4 col-12">
                  <div class="feature-box-long">
                    <i class="icon-diamond"></i>
                    <h3>Misión</h3>
                    <p>
                      Comercializamos, fundimos y exportamos metales preciosos con
                      transparencia y lealtad. Impactamos positivamente a nuestros
                      accionistas, colaboradores y aliados, promoviendo el desarrollo
                      sostenible y el respeto al medio ambiente.<br /><br />
                    </p>
                  </div>
                </div>
      
                <div class="col-md-4 col-sm-4 col-12">
                  <div class="feature-box-long">
                    <i class="icon-bank"></i>
                    <h3>Visión</h3>
                    <p>
                      Ser líderes en comercialización y exportación de metales
                      preciosos, nos movemos hacia el crecimiento rentable, honesto y
                      sostenible en el tiempo. Consolidaremos nuestro posicionamiento
                      a través de nuestra experiencia, nuestro trabajo preciso,
                      compromiso y seriedad.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
   
        )
      
    }
   
  }
   
  export default Nosotros;