import React from 'react'; 
import Slider from './Slider';
import Nosotros from './Nosotros';
import Valores from './Valores';
import Box from './Box';

 
 
class About extends React.Component {
 
	render() {
 
		return(
			<>
				<Slider/>
				<Nosotros/>
				<Valores/>
				<Box/>
			</>
 
		)
 
	}
 
}
 
export default About;