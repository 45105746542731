import React from 'react';

class Slider extends React.Component {
 
    render() {
   
        return (
          <div
          class="main-image-section"
          style={{backgroundImage: `url(img/magma-nosotros.jpg)`}}
        >

          <div class="medium-overlay"></div>
     
    
          <div class="main-image-content">
            <div class="container">
              <div class="row">
                <div class="col-md-6 col-sm-12 col-12 offset-md-3">
                  <div class="center-holder">
                    <h2>Comprar, fundir, certificar y exportar.</h2>
                    <p>Nosotros somos Magma</p>
                    <div class="mt-30">
                      <a href="#mas" class="primary-button button-md">SABER MÁS</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
   
        )
      
    }
   
  }
   
  export default Slider;