import React from 'react';

class Declaracion extends React.Component {
 
    render() {
   
        return (
            <div class="section-block-grey">
            <div class="container">
              <div class="section-heading center-holder">
                <h3>Declaración de confianza</h3>
                <div class="section-heading-line"></div>
              </div>
              <div class="row mt-50">
                <div class="col-md-4 col-sm-6 col-12">
                  <div class="feature-box">
                    <i class="icon-clock2"></i>
                    <h4>Transparencia</h4>
                    <p>
                      Compramos a través de negociaciones transparentes y equitativas
                      con nuestros proveedores, siguiendo todas las normas de
                      seguridad vigentes para monitorear toda la cadena de suministro.
                    </p>
                  </div>
                </div>
      
                <div class="col-md-4 col-sm-6 col-12">
                  <div class="feature-box">
                    <i class="icon-wallet2"></i>
                    <h4>Experiencia</h4>
                    <p>
                      Fundimos en procesos de alta calidad y mejora continua,
                      aplicando la experiencia que nos distingue dentro del sector
                      minero. <br /><br /><br />
                    </p>
                  </div>
                </div>
      
                <div class="col-md-4 col-sm-6 col-12">
                  <div class="feature-box">
                    <i class="icon-bar-graph-12"></i>
                    <h4>Exportación</h4>
                    <p>
                      Exportamos metales preciosos llevando adelante operaciones
                      internacionales seguras, ejecutando todos los requisitos legales
                      y directrices de la empresa para cumplir a sus asociados y
                      aliados comerciales.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
   
        )
      
    }
   
  }
   
  export default Declaracion;