import React from 'react';

class Box extends React.Component {
 
    render() {
   
        return (

            <div class="notice-section notice-section-sm border-top">
            <div class="container">
              <div class="row">
                <div class="col-md-12 col-sm-12 col-12">
                  <div class="mt-5">
                    <h6>
                      Operaciones transparentes promoviendo la integridad del sector
                      minero.
                    </h6>
                    <div class="section-heading-line-left"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
   
        )
      
    }
   
  }
   
  export default Box;