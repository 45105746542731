import React, { useEffect, useState } from "react";


function Cotizador() {
  let haveData = false;
  const [data, setdata] = useState([]);

  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };

  const getDatos = async () => {
    return await fetch("https://cwlc91chc8.execute-api.us-east-2.amazonaws.com/staging/rates", requestOptions)
      .then(response => response.json())
      .then((data) => setdata(data))
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    if (!haveData) {
      getDatos();
      haveData = true;
    }
    const interval = setInterval(() => {
      getDatos();
    }, 60000) // cada 60 seg
    return () => clearInterval(interval)
  }, [])

  return (
    <div class="section-block-parallax section-md cotizador" >
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-sm-5 col-12">
            <div class="section-heading secondary-color mt-15">
              <h3>Cotización Oro</h3>
              <div class="section-heading-line-left-cotizador"></div>
              <span>{data.date} - TRM: {data.trmHora}</span>
            </div>
          </div>
          <div class="col-md-7 col-sm-7 col-12">
            <div class="row">
              <div class="col-md-4 col-sm-4 col-12">
                <div class="counter-box secondary-color">
                  <h4>{data.AUozUSD === 0 ? '-' : data.AUozUSD}</h4>
                  <p>Au USD (OZ)</p>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-12">
                <div class="counter-box secondary-color">
                  <h4 >{data.trm === 0 ? '-' : data.trm}</h4>
                  <p>TMR USD-COP</p>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-12">
                <div class="counter-box secondary-color">
                  <h4 >{data.AUgrCOP === '0.0' ? '-' : data.AUgrCOP}</h4>
                  <p>AU COP (GR)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )

}

export default Cotizador;
