import React from "react";
import { Route, Switch } from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/home/Home';
import About from "./components/about/About";
import Cumplimiento from "./components/cumplimiento/Cumplimiento";

export function App() {
  return (
    <main>
 			<Header/>
        <Switch>
            <Route path="/" component={Home} exact title="Magma"/>
            <Route path="/nosotros" component={About} exact title="Magma - Nosotros"/>
            <Route path="/cumplimiento" component={Cumplimiento} exact title="Magma - Cumplimiento"/>

        </Switch>
      <Footer/>
</main>
);
}

