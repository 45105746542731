import React from 'react';

class Proceso extends React.Component {
 
    render() {
   
        return (
            <div className="section-block">
            <div className="container">
              <div className="section-heading center-holder">
                <span>Trazabilidad y Transparencia</span>
                <h3>PROCESO</h3>
                <div className="section-heading-line"></div>
              </div>
              <div className="row mt-60">
                <div className="col-md-4 col-sm-6 col-12">
                  <div className="feature-flex">
                    <div className="clearfix">
                      <div className="feature-flex-icon">
                        <i className="icon-balance3"></i>
                      </div>
                      <div className="feature-flex-content">
                        <h4><a href="#">Efectividad</a></h4>
                        <p>
                          Somos una comercializadora calificada como usuario
                          industrial de la Zona Franca de Rionegro. Nuestras
                          instalaciones cuentan con la infraestructura necesaria a fin
                          de ser ágiles en tiempos de respuesta a nuestros clientes y
                          aliados con la mayor efectividad.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
      
                <div className="col-md-4 col-sm-6 col-12">
                  <div className="feature-flex">
                    <div className="clearfix">
                      <div className="feature-flex-icon">
                        <i className="icon-diamond"></i>
                      </div>
                      <div className="feature-flex-content">
                        <h4><a href="#">Experiencia</a></h4>
                        <p>
                          Nuestro talento humano cuenta con todas las capacidades,
                          conocimientos y competencias para garantizar experiencias de
                          compra, fundición y exportación clara, responsable y
                          exitosa.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
      
                <div className="col-md-4 col-sm-6 col-12">
                  <div className="feature-flex">
                    <div className="clearfix">
                      <div className="feature-flex-icon">
                        <i className="icon-credit-card2"></i>
                      </div>
                      <div className="feature-flex-content">
                        <h4><a href="#">Calidad</a></h4>
                        <p>
                          En el exterior nuestros aliados estratégicos y clientes nos
                          perciben como una empresa honesta y comprometida con la
                          calidad y transparencia en cada una de nuestras operaciones.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
   
        )
      
    }
   
  }
   
  export default Proceso;