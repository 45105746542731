import React from 'react';

class Politicas extends React.Component {
 
    render() {
   
        return (
            <div class="section-block pb-0">
            <div class="container">
              <div class="row">
                <div class="col-md-5 col-sm-6 col-12">
                  <div class="section-heading mt-30">
                    <span>Monitoreo y Control.</span>
                    <h4>POLÍTICAS</h4>
                    <div class="section-heading-line-left"></div>
                  </div>
                  <div class="text-content mt-25">
                    <p>
                      Magma como comercializador de metales preciosos, afianza su
                      compromiso de mejorar continuamente en la gestión de riesgos y
                      controles concernientes a garantizar la procedencia legítima del
                      metal precioso y el uso eficiente de los recursos que llegan a
                      nuestras fundidoras para exportación. Analizamos y verificamos
                      cada detalle a fin de garantizar la seguridad de las operaciones
                      y la correcta vinculación de aliados a través de nuestros
                      sistemas de transparencia, cumpliendo a cabalidad con la
                      normatividad legal vigente para la prevención de lavado del
                      dinero, el financiamiento del terrorismo y la financiación de
                      proliferación de armas de destrucción masiva.
                    </p>
                  </div>
                </div>
                <div class="col-md-7 col-sm-6 col-12">
                  <img src="img/magma-politicas.jpg" alt="image" />
                </div>
              </div>
            </div>
          </div>
   
        )
      
    }
   
  }
   
  export default Politicas;