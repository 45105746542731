import React from 'react';

class Seccion extends React.Component {
 
    render() {
   
        return (
            <div class="section-block-grey">
            <div class="container">
              <div class="section-heading center-holder">
                <h3>Cumplimiento</h3>
                <div class="section-heading-line"></div>
              </div>
              <div class="row mt-60">
                <div class="col-md-4 col-sm-12 col-12">
                  <div class="serv-section-2">
                    <div class="serv-section-2-icon">
                      <i class="icon-checked"></i>
                    </div>
                    <div class="serv-section-desc">
                      <h4>Combatir</h4>
                    </div>
                    <div class="section-heading-line-left"></div>
                    <p>
                      Nos ceñimos a las directrices de la OCDE, a las recomendaciones
                      de la GAFI y a las normas vigentes de prevención de lavado de
                      activos. Además, nos acogemos a la guía de la debida diligencia
                      en todos sus procesos con el propósito de combatir el lavado del
                      dinero, el financiamiento del terrorismo y financiación de
                      proliferación de armas de destrucción masiva.
                    </p>
                  </div>
                </div>
      
                <div class="col-md-4 col-sm-12 col-12">
                  <div class="serv-section-2 serv-section-2-act">
                    <div class="serv-section-2-icon serv-section-2-icon-act">
                      <i class="icon-checked"></i>
                    </div>
                    <div class="serv-section-desc">
                      <h4>Incentivar</h4>
                    </div>
                    <div class="section-heading-line-left"></div>
                    <p>
                      Nuestro compromiso es la transparencia, la seguridad y la mejora
                      continúa dando cumplimiento a los requisitos legales y
                      tributarios nacionales e internacionales. De la misma forma, se
                      lo exigimos a todos nuestros proveedores con el objetivo de
                      incentivar y promover la claridad en todas las etapas del
                      proceso.<br />
                      <br />
                    </p>
                  </div>
                </div>
      
                <div class="col-md-4 col-sm-12 col-12">
                  <div class="serv-section-2">
                    <div class="serv-section-2-icon">
                      <i class="icon-checked"></i>
                    </div>
                    <div class="serv-section-desc">
                      <h4>Detectar</h4>
                    </div>
                    <div class="section-heading-line-left"></div>
                    <p>
                      Nos destacamos por llevar a cabo operaciones dentro de nuestro
                      sistema de transparencia capaz de monitorear y detectar
                      actividades sospechosas en el sector real, dando estricto
                      cumplimiento a la normatividad vigente que rige e incentiva la
                      participación en el sector minero del país.<br /><br /><br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
   
        )
      
    }
   
  }
   
  export default Seccion;