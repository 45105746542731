import React from 'react';
class Header extends React.Component {
 
    render() {
   
        return (
                <header>
                <nav id="navigation4" class="container navigation">
                <div class="nav-header">
                    <a class="nav-brand" href="/">
                    <img
                        src="img/logos/logo.png"
                        class="main-logo"
                        alt="logo"
                        id="main_logo"
                    />
                    </a>
                    <div class="nav-toggle"></div>
                </div>
                <div class="nav-menus-wrapper">
                    <ul class="nav-menu align-to-right">
                    <li>
                        <a href="/">Inicio</a>
                    </li>
                    <li>
                        <a href="/nosotros">Nosotros</a>
                    </li>
                    <li>
                        <a href="/cumplimiento">Cumplimiento</a>
                    </li>
                    </ul>
                </div>
                </nav>
                </header>
    
   
        )
      
    }
   
  }
   
  export default Header;
