import React from 'react';

class Compromiso extends React.Component {
 
    render() {
   
        return (
<div class="section-block">
      <div class="container">
        <div class="section-heading center-holder">
          <span>Compromiso</span>
          <h3>Sistema de gestion de transparencia</h3>
          <div class="section-heading-line"></div>
          <p>
            Competencias de excelencia, confianza y colaboración en todos los
            niveles de la organización
          </p>
        </div>
        <div class="row mt-50">
          <div class="col-md-3 col-sm-6 col-12">
            <div class="service-block">
              <img
                src="img/magma-compromiso-legalidad.jpg"
                alt="magma compromiso legalidad"
              />
              <div class="clearfix">
                <div class="service-block-number">
                  <h5>01</h5>
                </div>
                <div class="service-block-title">
                  <h4><a href="#">Legalidad</a></h4>
                </div>
              </div>
              <p>
                Garantizar transparencia y legalidad en cada uno de nuestros
                procesos de compra, fundición y exportación.
              </p>
            </div>
          </div>

          <div class="col-md-3 col-sm-6 col-12">
            <div class="service-block">
              <img
                src="img/magma-compromiso-sostenibilidad.jpg"
                alt="magma compromiso sostenibilidad"
              />
              <div class="clearfix">
                <div class="service-block-number">
                  <h5>02</h5>
                </div>
                <div class="service-block-title">
                  <h4><a href="#">Sostenibilidad</a></h4>
                </div>
              </div>
              <p>
                Asegurar la sostenibilidad y permanencia de Magma en las futuras
                generaciones a través de una gestión ética, transparente y
                responsable.
              </p>
            </div>
          </div>

          <div class="col-md-3 col-sm-6 col-12">
            <div class="service-block">
              <img
                src="img/magma-compromiso-mejora-continua.jpg"
                alt="magma compromiso mejora continua"
              />
              <div class="clearfix">
                <div class="service-block-number">
                  <h5>03</h5>
                </div>
                <div class="service-block-title">
                  <h4><a href="#">Mejora continua</a></h4>
                </div>
              </div>
              <p>
                Desarrollar procesos de mejora continua que nos permitan
                fomentar un sistema de producción amigable con el medio
                ambiente.
              </p>
            </div>
          </div>

          <div class="col-md-3 col-sm-6 col-12">
            <div class="service-block">
              <img
                src="img/magma-compromiso-excelencia.jpg"
                alt="magna compromiso excelencia"
              />
              <div class="clearfix">
                <div class="service-block-number">
                  <h5>04</h5>
                </div>
                <div class="service-block-title">
                  <h4><a href="#">Excelencia</a></h4>
                </div>
              </div>
              <p>
                Impulsar el éxito de Magma en el talento y las habilidades de
                nuestro equipo, con una estrategia de negocios que requiere
                incorporar y ejecutar en todos los procesos diarios de la
                empresa.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
   
        )
      
    }
   
  }
   
  export default Compromiso;