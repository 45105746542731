import React from 'react';

class Valores extends React.Component {
 
    render() {
   
        return (

            <div
            class="section-block-bg"
            style={{backgroundImage: `url(img/magma-valores-corporativos.jpg)`}}
          >
            <div class="container">
              <div class="row">
                <div class="col-md-6 offset-sm-6">
                  <div class="section-heading">
                    <span>Transparencia y cultura abierta</span>
                    <h4>Valores Corporativos</h4>
                    <div class="section-heading-line-left"></div>
                  </div>
                  <div class="text-content-big mt-20">
                    <p>
                      Somos veraces, íntegros y éticos con todos nuestros grupos de
                      interés. Seguimos los lineamientos de la OCDE y los protocolos
                      de la debida diligencia, lo que nos permite proyectar confianza
                      y una imagen positiva en todas nuestras operaciones.
                    </p>
                  </div>
                  <div class="mt-25">
                    <ul class="primary-list">
                      <li>
                        <i class="fa fa-check-square"></i>Integridad: Operamos bajo la
                        brújula de la moral y las buenas costumbres, somos coherentes
                        con los valores que defendemos en cada una de nuestras
                        políticas de transparencia, honestidad y responsabilidad.
                      </li>
                      <li>
                        <i class="fa fa-check-square"></i>Responsabilidad:
                        Dignificamos nuestra gestión empresarial incorporando en todas
                        nuestras decisiones respeto, transparencia e integridad.
                      </li>
                      <li>
                        <i class="fa fa-check-square"></i>Disponibilidad al cambio:
                        Estamos abiertos a la innovación en nuestros procesos, por
                        ello trabajamos continuamente para mejorar, con el fin de ser
                        cada día más productivos y perdurar en el tiempo.
                      </li>
                      <li>
                        <i class="fa fa-check-square"></i>Pasión: Queremos lo que
                        hacemos y trabajamos cada día por ser mejores en lo que
                        ofrecemos. Aplicamos en todas nuestras operaciones buenas
                        prácticas que enfatizan nuestro compromiso con el medio
                        ambiente y contribuyen con el desarrollo económico y social
                        local del sector minero.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
   
        )
      
    }
   
  }
   
  export default Valores;