import React from 'react';

class Footer extends React.Component {
 
    render() {
   
        return (
            <footer>
            <div class="container">
              <div class="row">
                <div class="col-md-12 col-sm-6 col-12">
                
                    <img src="img/logos/logo-footer.png" alt="footer-logo" />
                    <div class="top-bar-info">
              <ul className="secondary-color">
                <li><i class="fa fa-phone"></i>321 531 0130</li>
                <li></li>
                <li><i class="fa fa-envelope"></i>gerencia@ magmazf.com</li>
                <li></li>
                <li>
                  <i class="fa fa fa-globe"></i>Zona Franca Rionegro. Bodega N°
                  91
                </li>
                <li></li>
              </ul>
            </div>
           
                </div>

              </div>
              <div className="footer-bar">
                <p>
                  <span className="secondary-color"
                    ><a href="https://www.ideamos.digital/" className="secondary-color" target="_blank"> ideamos.digital </a></span
                  >
                  © 2021. Derechos reservados.
                </p>
              </div>
            </div>
          </footer>
   
        )
      
    }
   
  }
   
  export default Footer;

  